<template>
  <div>
    <div class="container mt-3">
      <div
        v-if="isActive"
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
        data-interval="6000"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li v-for="(img, i) in images" :key="img.id" data-target="#carouselExampleIndicators" :data-slide-to="i+1"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100"
              src="../assets/mata_anak.png"
              alt="First slide"
            />
          </div>
          <div :class="'carousel-item '" v-for="img in images" :key="img.id">
            <img
              :id="`IMG${img.id}`"
              class="d-block w-100"
              :src="`${base}/gallery/api/image/alt/${img.imgUrl}`"
               @error="$event.target.src=defaultImage"
            />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import notAvailable from '../assets/image-not-available.jpg'
import axios from "@/axios";
export default {
  data() {
    return {
      isActive: false,
      images: [
       
      ],
      defaultImage: notAvailable,
      base: "",
      page: 0
    };
  },
  methods: {
    getCarousels(size) {
      const dateNow = new Date().toISOString().slice(0, 10)
      const params = new URLSearchParams();
      params.append('size', size)
      params.append('page', this.page)
      params.append('tanggalMulai.lessOrEqualThan', dateNow)
      params.append('tanggalAkhir.greaterOrEqualThan', dateNow)
      params.append('sort','tanggalMulai,desc')
      axios
        .get(`/api/carousels`, {params})
        .then(data => {
          const XcountAll = data.headers['x-total-count']
          this.images.push(...data.data);         
          setTimeout(()=> {
            this.isActive = true
          }, 500)
           setTimeout(()=> {
            $('.carousel-control-next').click()
          }, 1000)
          if(XcountAll > this.images.length) {
            this.page++
            this.getCarousels(5)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.base = process.env.VUE_APP_BASE_URL;
    this.getCarousels(5);

  },
};
</script>

<style>
.carousel-item img {
  position: block;
  top: 5px;
  left: 0;
  min-width: 100%;
  height: 80vh;
  max-width: none;
}

img {
  max-width: 100%;
  width: auto   9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
</style>
